/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Trans, useTranslation } from "react-i18next";
import { StaticImage } from "gatsby-plugin-image";

const WhoWeAre = (): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Grid
        container
        spacing={4}
        direction={isMd ? "row" : "column"}
        alignItems="flex-start"
      >
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          xs={12}
          md={6}
        >
          <Box>
            <Typography variant={"h4"} gutterBottom sx={{ fontWeight: 700 }}>
              <Trans>Our story</Trans>
            </Typography>
            <Typography
              component={"p"}
              color={"text.secondary"}
              sx={{
                mt: "1.5rem",
                mb: "1rem",
                "& br": {
                  display: "block",
                  content: "' '",
                  mb: "1rem",
                },
              }}
            >
              <Trans i18nKey="OUR_STORY">
                Bogunov inc. is founded in 2016, Tokyo, Japan.
                <br />
                Our team has over 12 years of high technical and creative
                capability.
                <br />
                We have been working with companies in 🇯🇵 Japan, 🇺🇸 United
                States, 🇪🇺 Europe and 🇷🇺 Russia.
                <br />
                Our goal is to develop sophisticated and usability-focused
                software.
                <br />
                Based on the operating and planning experience acquired in more
                than 150 projects, our development process is crystal clear.
                <br />
                🏆 One of the apps, we were in charge of UI/UX, received the
                Seven Bank Award in FiBC 2018.
                <br />
                In 2018 and 2019, our team participated World Wide Developers
                Conference in Apple, California. In the conference, we discuss
                new features of apps and direction for app development market in
                vision of Apple. In their sessions, we have chance to ask about
                tips to improve your apps in many ways with Apple directors.
              </Trans>
            </Typography>
            <StaticImage
              src="./stamp.jpg"
              height={95}
              alt="stamp"
              style={{ marginRight: "1.5rem" }}
            />
            <StaticImage
              src="../../../../layouts/Main/components/Footer/security_action.webp"
              height={95}
              alt="security action logo"
              style={{}}
            />
          </Box>
        </Grid>
        <Grid
          item
          container
          alignItems={"center"}
          justifyContent="center"
          xs={12}
          md={6}
        >
          <Box>
            <Typography variant={"h4"} gutterBottom sx={{ fontWeight: 700 }}>
              <Trans>Why Us?</Trans>
            </Typography>
            <Typography
              component={"p"}
              color={"text.secondary"}
              sx={{
                mt: "1.5rem",
                mb: "1rem",
                ml: "-1.4rem",
                "& li": {
                  mb: "1rem",
                },
              }}
            >
              <Trans i18nKey="WHY_US">
                <ol>
                  <li>
                    <b>Developing Services to the World</b>
                    <br />
                    Both officers and members have more than 10 years of
                    overseas business experience. We will improve your app
                    service content by referring to similar apps that are
                    popular overseas. We can support your business from local
                    competition investigation to development and operation.
                  </li>
                  <li>
                    <b>Knowledge of Successful Subscription App</b>
                    <br />
                    The smartphone app market is saturated worldwide and apps
                    that are not used and apps that are used is polarized.
                    However, some apps developed by our company, there are 1
                    million monthly billing users. We also support apps that
                    acquire more than 2500 new paying users every month.
                  </li>
                  <li>
                    <b>Providing One Stop Service</b>
                    <br />
                    We can handle everything from app consulting to marketing
                    research, strategy planning, development, server settings,
                    app application, version update, and support while embodying
                    your ideas. In particular, we appreciated by planning and
                    proposal capabilities.
                  </li>
                  <li>
                    <b>Connection with Apple Inc.</b>
                    <br />
                    Every year, we are called locally as a developer at the
                    Worldwide Developers Conference hosted by Apple
                    headquarters, and has a direct connection with Apple
                    headquarters in the United States. In particular, we
                    regularly interact with the app subscription department to
                    get advice on monetizing paid apps.
                  </li>
                </ol>
              </Trans>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WhoWeAre;
