import React, { useEffect } from "react";
import { alpha, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Container from "../../../../components/Container";
import { Trans, useTranslation } from "react-i18next";
import background from "./bg.webp";

const Hero = (): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box
      position={"relative"}
      sx={{
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: {
          xs: "right -80px top 40px",
          sm: "right top 10px",
        },
        marginTop: -13,
        paddingTop: 13,
        minHeight: { xs: 400, sm: 500, md: 600 },
        display: "flex",
        alignItems: "center",
        "&:after": {
          position: "absolute",
          content: '" "',
          width: "100%",
          height: "100%",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 1,
          background: "#161c2d",
          opacity: 0.6,
        },
      }}
    >
      <Container zIndex={3} position={"relative"}>
        <Box>
          <Typography
            variant="h2"
            gutterBottom
            sx={{
              fontWeight: 900,
              color: "common.white",
              textTransform: "uppercase",
            }}
          >
            <Trans>About us</Trans>
          </Typography>
          <Typography
            variant="h6"
            component="p"
            color="text.primary"
            sx={{
              color: "common.white",
            }}
          >
            <Trans>We make ideas come true</Trans>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;
