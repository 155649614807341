import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Trans, useTranslation } from "react-i18next";
import EmailIcon from "@mui/icons-material/Email";
import { useWidgetState } from "@livechat/widget-react";

const Work = (): JSX.Element => {
  const { t } = useTranslation();
  const widgetState = useWidgetState();

  return (
    <Box>
      <Box>
        {/*<Typography*/}
        {/*  sx={{*/}
        {/*    textTransform: "uppercase",*/}
        {/*    fontWeight: "medium",*/}
        {/*  }}*/}
        {/*  gutterBottom*/}
        {/*  color={"secondary"}*/}
        {/*  align={"center"}*/}
        {/*>*/}
        {/*  Our work*/}
        {/*</Typography>*/}
        <Typography variant={"h4"} sx={{ fontWeight: 700 }} align={"center"}>
          <Trans>Ready to work together?</Trans>
        </Typography>
        <Typography
          variant="h6"
          component="p"
          color="text.secondary"
          align={"center"}
          sx={{ fontWeight: 400 }}
        >
          <Trans>Don’t hesitate to contact us</Trans>
        </Typography>
        <Box marginTop={2} display={"flex"} justifyContent={"center"}>
          {widgetState?.availability === "online" ? (
            <Button
              color={"primary"}
              variant={"contained"}
              size={"large"}
              onClick={() => {
                // @ts-ignore
                LiveChatWidget.call("maximize");
              }}
            >
              <Trans>Talk to us</Trans>
            </Button>
          ) : (
            <span style={{ textAlign: "center" }}>
              <Button
                color={"primary"}
                variant={"contained"}
                size={"large"}
                href="mailto:info@bogunov.co.jp"
                startIcon={<EmailIcon />}
              >
                <Trans>Email us</Trans>
              </Button>
              <br />
              <br />
              <Typography variant="subtitle1">
                <Trans i18nKey={"TURN_OFF_YOUR_ADD_BLOCKER_TO_CHAT"}>
                  Turn off your ad blocker to chat with us
                  <br />
                  or click the button to send us an email
                </Trans>
              </Typography>
            </span>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Work;
